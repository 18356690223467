import React from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Navigation from "../Navigation/navigation";
import PandaBuySignUpLink from "../PandaBuySignUpLink/PandaBuySignUpLink";
import Footer from "../Footer/Footer";
import { Helmet } from "react-helmet";
import { AgentName } from "../../config/config";
const ProductDetails = () => {
  const { productId } = useParams();
  const productsState = useSelector((state) => state.products);
  const productData = productsState.products.find(
    (product) => product.productId === productId
  );

  if (!productsState.products.length) {
    console.log(productsState);
    return (
      <div>
        <Navigation />
        <h1 className="text-center">Loading...</h1>
        <Footer />
      </div>
    );
  }

  if (!productData) {
    return (
      <div>
        <Navigation />
        <h1 className="text-center">Product not found</h1>
        <Footer />
      </div>
    );
  }

  return (
    <div className="">
      <PandaBuySignUpLink />
      <Navigation />
      <Helmet>
        <title>
          {productData.itemName} - Link {AgentName}, Hagobuy, CNFans, Sugargoo,
          Superbuy, CSSBuy, Weidian, Taobao
        </title>
        <meta
          name="description"
          content={`Buy ${productData.itemName} at ${AgentName}, Hagobuy, CNFans, Sugargoo, Superbuy, CSSBuy, Weidian, and Taobao. Discover a vast collection of fashion replicas on our web-based spreadsheet. Featuring 5000+ products from various agents and platforms. Find quality check links, QC services, and add products to your watchlist for easy tracking.`}
        />
        <meta
          name="keywords"
          content={`${productData.itemName}, ${AgentName}, Hagobuy, CNFans, Sugargoo, Superbuy, CSSBuy, Weidian, Taobao`}
        />
        <meta name="robots" content="index, follow" />
        <link
          rel="canonical"
          href={`https://joyabuysheets.com/products/${productData.searchName}/${productData.productId}`}
        />
        <link
          rel="sitemap"
          type="application/xml"
          title="Sitemap"
          href="https://joyabuysheets.com/sitemap.xml"
        />
        <script type="application/ld+json" src="/schema.org.jsonld"></script>
      </Helmet>

      <div
        class="ProductDetailsDiv"
        className="ProductDetailsDiv mt-12 flex max-lg:flex-col justify-evenly shadow-md max-lg:w-[450px] max-sm:w-full max-lg:ml-[23%] max-md:ml-[15%] max-sm:ml-0 "
      >
        <div className=" flex max-lg:justify-center">
          <img
            src={productData.pic_url}
            alt={`${productData.itemName} pandabuy`}
            className="cardlist max-sm:h-[335px] max-sm:w-full md:h-[450px] md:w-[450px]"
          />
        </div>
        <div
          class="ProductDetailsDiv"
          className="ProductDetailsDiv flex flex-col lg:gap-y-[6.4rem] max-lg:gap-y-[2.4rem] max-sm:w-full max-sm:p-6 "
        >
          <div className="pt-12 flex-col  max-lg:items-center max-lg:justify-center ">
            <div className="flex lg:justify-between max-lg:justify-between max-lg:gap-x-4 max-sm:gap-x-0 lg:items-center  ">
              {" "}
              {/* Add items-center */}
              <div className="">
                <p className="cardPriceTitle">Product Title</p>
                <h2 className="cardTitle text-nowrap overflow-hidden ">
                  {productData.itemName}
                </h2>
              </div>
              <div className="flex flex-col ">
                <p className="cardPriceTitle">Product Price</p>
                <p className="cardTitle ">${productData.price}</p>
              </div>
            </div>

            <div className="flex lg:justify-between max-lg:justify-between  max-lg:gap-x-4 lg:items-center mt-6">
              {" "}
              {/* Add items-center */}
              <div className="">
                <p className="cardPriceTitle">Product Dimensions</p>
                <p className="cardTitle">{productData.dimensions}</p>
              </div>
              <div>
                <p className="cardPriceTitle">Product Sales</p>
                <p className="cardTitle">{productData.sales}</p>
              </div>
            </div>

            <div className="flex lg:justify-between max-lg:justify-between max-lg:gap-x-4 lg:items-center mt-6">
              {" "}
              {/* Add items-center */}
              <div>
                <p className="cardPriceTitle">Product Weight</p>
                <p className="cardTitle">{productData.weight} G</p>
              </div>
              <div>
                <a
                  style={{ color: "var(--text-header-color)" }}
                  href={productData.qcLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn5 "
                >
                  QC LINK
                </a>
              </div>
            </div>
          </div>

          <div className="flex gap-x-8 flex-wrap max-lg:hidden max-md:hidden max-sm:hidden  ">
            <div className="flex flex-col gap-y-4 w-[150px]">
              <a
                href={productData.pandaBuyAffiliateLink}
                target="_blank"
                rel="noopener noreferrer"
                className="btn4 text-[16px] "
              >
                Buy at Pandabuy
              </a>
              <a
                href={productData.hagoBuyAffiliateLink}
                target="_blank"
                rel="noopener noreferrer"
                className="btn4 text-[16px]"
              >
                Buy at Hagobuy
              </a>
            </div>
            <div className="flex flex-col gap-y-4 w-[150px]">
              <a
                href={productData.cnFansAffiliateLink}
                target="_blank"
                rel="noopener noreferrer"
                className="btn4 text-[16px]"
              >
                Buy at CNFans
              </a>
              <a
                href={productData.sugargooAffiliateLink}
                target="_blank"
                rel="noopener noreferrer"
                className="btn4 text-[16px]"
              >
                Buy at Sugargoo
              </a>
            </div>
            <div className="flex flex-col gap-y-4 w-[150px]">
              <a
                href={productData.superBuyAffiliateLink}
                target="_blank"
                rel="noopener noreferrer"
                className="btn4 text-[16px]"
              >
                Buy at Superbuy
              </a>
              <a
                href={productData.cssBuyAffiliateLink}
                target="_blank"
                rel="noopener noreferrer"
                className="btn4 text-[16px]"
              >
                Buy at CSSBuy
              </a>
            </div>
          </div>

          <div className="flex justify-between lg:hidden">
            <div className="flex flex-col gap-y-4 w-[150px]">
              <a
                href={productData.pandaBuyAffiliateLink}
                target="_blank"
                rel="noopener noreferrer"
                className="btn4 text-[16px] "
              >
                Buy at Pandabuy
              </a>
              <a
                href={productData.hagoBuyAffiliateLink}
                target="_blank"
                rel="noopener noreferrer"
                className="btn4 text-[16px]"
              >
                Buy at Hagobuy
              </a>
              <a
                href={productData.cnFansAffiliateLink}
                target="_blank"
                rel="noopener noreferrer"
                className="btn4 text-[16px]"
              >
                Buy at CNFans
              </a>
            </div>
            <div className="flex flex-col gap-y-4 w-[150px]">
              <a
                href={productData.superBuyAffiliateLink}
                target="_blank"
                rel="noopener noreferrer"
                className="btn4 text-[16px]"
              >
                Buy at Superbuy
              </a>
              <a
                href={productData.cssBuyAffiliateLink}
                target="_blank"
                rel="noopener noreferrer"
                className="btn4 text-[16px]"
              >
                Buy at CSSBuy
              </a>
              <a
                href={productData.sugargooAffiliateLink}
                target="_blank"
                rel="noopener noreferrer"
                className="btn4 text-[16px]"
              >
                Buy at Sugargoo
              </a>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ProductDetails;
